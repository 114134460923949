.flex{
    display: flex !important;
}

.justify-between{
    justify-content: space-between;
}

.align-items-center {
    align-items: center!important
}
.card_magic{
    background-color: white;
}

/**{*/
/*    border-radius: 0 !important;*/
/*}*/

body {
    /*background-image: url("./bg.svg");*/
    /*background-image: url("https://wallpapercave.com/wp/wp10054793.png");*/
    background: white;
}

.ant-select-arrow {
    color: #004e98 !important; /* Change to the color you desire */
}

.lists_table{
    overflow: auto;
    height: 88vh !important;
}


*::-webkit-scrollbar {
    width: 0.2em;
    height: 0.2em;
}

*::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #1677ff;
}

*::-webkit-scrollbar-thumb {
    background-color: #000000;
    outline: 1px solid #ffffff;
}

.form {
    --input-focus: #2d8cf0;
    --font-color: #323232;
    --font-color-sub: #666;
    --bg-color: #fff;
    --main-color: #323232;
    padding: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    border-radius: 5px;
    border: 2px solid var(--main-color);
    box-shadow: 4px 4px var(--main-color);
    width: 37%;
}

.title {
    color: var(--font-color);
    font-weight: 900;
    font-size: 25px;
    margin-bottom: 25px;
}

.title span {
    color: var(--font-color-sub);
    font-weight: 600;
    font-size: 17px;
}

.forgot-pass {
    color: #4285F4;
    font-weight: 600;
    font-size: 17px;
    cursor: pointer;
}

.input {
    width: 33vw;
    height: 40px;
    border-radius: 5px;
    border: 2px solid var(--main-color);
    background-color: var(--bg-color);
    box-shadow: 4px 4px var(--main-color);
    font-size: 15px;
    font-weight: 600;
    color: var(--font-color);
    padding: 5px 10px;
    outline: none;
}

.input::placeholder {
    color: var(--font-color-sub) !important;
    opacity: 0.8 !important;
}
.input::-webkit-input-placeholder{
    font-size: 15px;
    font-weight: 600;
    color: var(--font-color-sub) !important;
    opacity: 0.8 !important;
}

.login_component.form .ant-input::placeholder {
    /* Your styles here */
    color:  var(--font-color-sub) !important; /* Change the color to your preference */
}
.input:focus {
    border: 2px solid var(--input-focus);
}

.login-with {
    display: flex;
    gap: 20px;
}

.button-log {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 2px solid var(--main-color);
    background-color: var(--bg-color);
    box-shadow: 4px 4px var(--main-color);
    color: var(--font-color);
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    width: 24px;
    height: 24px;
    fill: var(--main-color);
}

.button-log:active, .button-confirm:active {
    box-shadow: 0px 0px var(--main-color);
    transform: translate(3px, 3px);
}

.button-confirm {
    margin: 30px auto 0 auto;
    width: 120px;
    height: 40px;
    border-radius: 5px;
    border: 2px solid var(--main-color);
    background-color: var(--bg-color);
    box-shadow: 4px 4px var(--main-color);
    font-size: 17px;
    font-weight: 600;
    color: var(--font-color);
    cursor: pointer;
}

.button-confirm:hover{
    background-color: var(--font-color-sub);
    color: var(--bg-color);
}
.button-confirm:active{
    background-color: var(--main-color);
    color: var(--bg-color);
}

.ant-btn-primary {
    color: #fff !important;
    background: #000 !important;
    box-shadow: none;
}

.ant-pagination .ant-pagination-item-active{
    border-color: #000 !important;
}
.ant-pagination .ant-pagination-item-active a{

    color: #000 !important;
}

button:disabled {
    opacity: 0.5; /* You can adjust the value to make it lighter or darker */
}
